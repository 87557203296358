
<template>
  <div class="not-found">
    <img src="@/assets/images/not_found.gif" alt="" />
  </div>
</template>
<script>
export default {
  name: 'failNo',
  components: {}
}
</script>
<style lang="less" scoped>
.not-found {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.not-found img {
  width: 100%;
  height: 100%;
}
</style>>