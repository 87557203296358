<template>
 <!-- <router-view></router-view> -->
 <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
export default {
  name: 'App',
  provide () {
   return{
     reload: this.reload
   }
 },
 data() {
   return {
       isRouterAlive: true
   }
 },
 methods:{
   reload(){
     this.isRouterAlive = false
     this.$nextTick(function(){
       this.isRouterAlive = true
     })
   }
 }
}
</script>

<style lang="less" scoped></style>
