import { render, staticRenderFns } from "./CmsMain.vue?vue&type=template&id=5796fecd&scoped=true&"
import script from "./CmsMain.vue?vue&type=script&lang=js&"
export * from "./CmsMain.vue?vue&type=script&lang=js&"
import style0 from "../../style/main/main.css?vue&type=style&index=0&id=5796fecd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5796fecd",
  null
  
)

export default component.exports