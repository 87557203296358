import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: "",
    userInfo: {},
    isGaveALike: [],
  },
  getters: {
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken
    },
    updataUserInfo(state, payload) {
      state.userInfo = payload
    },
    updateisGaveALike(state,isGaveALike){
      state.isGaveALike = isGaveALike
    }
  },
  modules: {
  }
})
